<template>
    <div class="comp-container">
        <iframe src="static/htmls/player-guider.html" frameborder="0"></iframe>
    </div>
</template>

<script>
export default {
    name: 'VueThreejsPlayerGuider',
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>

</style>